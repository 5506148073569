import React, {Component} from 'react';
import Menu from "./Menu";
import Crumbs from "./Crumbs";
import Envelope from "./Envelope";
import Profile from "./Profile";
import Button from "./Button";
import Modal from "./Modal";

class App extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={'layout'}>
        <div className={'sider'}>
          <Menu/>
        </div>
        <div className={'header'}>
          <Crumbs/>
          <Envelope/>
          <Profile/>
        </div>
        <div className={'content'}>
          <div className={'conteiner'}>
            <Button icon={null} text={'+  Создать свой магазин'} style={{ backgroundColor: 'rgb(85, 201, 123)', width: '185px', height: '36px' }}/>
            <div className={'headline'}>Управление магазинами</div>
            <Modal/>
          </div>
          <div className={'table-holder'}>
            <div className={'table'}>
              <div className={'text-caption'}>№</div>
              <div className={'text-caption'}>Название</div>
              <div className={'text-caption'}>Адрес</div>
              <div className={'text-caption'}>Действие</div>
              <div className={'text-data'}>12345</div>
              <div className={'text-data'}>Дипломная работа</div>
              <div className={'text-data'}>http://www.work5.ru</div>
              <div className={'button-holder'}>
                <Button icon={'pencil1.png'} text={'Редактировать'} style={{backgroundColor: 'rgb(85, 201, 123)', width: '149px', height: '36px'}}/>
                <Button icon={'close.png'} text={'Удалить'} style={{backgroundColor: 'rgb(177, 177, 177)', width: '149px', height: '36px'}}/>
              </div>
              <div className={'text-data'}>12345</div>
              <div className={'text-data'}>Дипломная работа</div>
              <div className={'text-data'}>http://www.work5.ru</div>
              <div className={'button-holder'}>
                <Button icon={'pencil1.png'} text={'Редактировать'} style={{backgroundColor: 'rgb(85, 201, 123)', width: '149px', height: '36px'}}/>
                <Button icon={'close.png'} text={'Удалить'} style={{backgroundColor: 'rgb(177, 177, 177)', width: '149px', height: '36px'}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
