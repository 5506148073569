import React, {Component} from 'react';

class Button extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {icon, text, style} = this.props;

    return (
      <div className={'button'} style={style}>
          {icon ? (
            <div
              className={'icon'}
              style={{background: `url('${icon}') center no-repeat`, width: '36px', height: '36px'}}
            > </div>
          ) : null}
          <div className={'text'}>{text}</div>
      </div>
    );
  }
}

export default Button;
