import React, {Component} from 'react';

class Crumbs extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={'crumbs'}>
        {'Главная > Мои магазины'}
      </div>
    );
  }
}

export default Crumbs;
