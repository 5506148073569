import React, {Component} from 'react';

class Profile extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={'profile'}>
        <div className={'holder'}>
          <div className={'foto'}></div>
        </div>
        <div className={'text'}>Максим Добжанский</div>
      </div>
    );
  }
}

export default Profile;
