import React, {Component} from 'react';
import Button from "./Button";

class Modal extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {

    return (
      <div className={'modal'}>
        <div className={'modal-header'}>
          <div className={'title'}>Создать магазин</div>
          <div className={'close'} style={{ background: `url('close.png') center no-repeat`, }}> </div>
        </div>
        <div className={'modal-content'}>
          <div className={'custom'}>
            <div className={'input-container sample'}>
              <div className={'caption'}>Шаблон</div>
              <div className={'input-holder'}>
                <div className={'placeholder'}>Стандартная</div>
                <div className={'down-arrow'}></div>
              </div>
            </div>
            <div className={'input-container сolor-scheme'}>
              <div className={'caption'}>Цветовая схема</div>
              <div className={'input-holder'}>
                <div className={'placeholder'}>Класическая</div>
                <div className={'down-arrow'}></div>
              </div>
            </div>
          </div>
          <div className={'domen'}>
            <div className={'caption'}>Домен</div>
            <div className={'domen-conteiner'}>
              <div className={'placeholder'}>Например: ivangrozniy</div>
              <Button text={'.work5.ru'} style={{ backgroundColor: 'rgb(102, 102, 204)', width: '151px', height: '36px'}}/>
            </div>
          </div>
          <div className={'info'}>
            <div className={'icon'}> </div>
            <div className={'text'} style={{ height: '76px'}}>
              <p>
              В названии домена разрешены латиница и “-”, запрещается использовать
              словосочетания Work5. После создания магазина Вы сможете прикрутить свой
              домен ( например ivangrozniy.ru ). Подробнее по <a href='http://work5.aeve.ru'>ссылке</a>
              </p>
            </div>
          </div>
        </div>
        <div className={'modal-footer'}>
          <Button text={'Отмена'} style={{backgroundColor: 'rgb(177, 177, 177)', width: '151px', height: '36px'}}/>
          <Button text={'Создать'} style={{backgroundColor: 'rgb(85, 201, 123)', width: '151px', height: '36px'}}/>
        </div>
      </div>
    );
  }
}

export default Modal;
