import React, {Component} from 'react';
import MenuElement from "./MenuElement";

class Menu extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const menuElements = [
      {icon: 'web-page-home.png', text: 'Главная', selected: false},
      {icon: 'shopping-cart.png', text: 'Мои магазины', selected: true},
      {icon: 'megaphone.png', text: 'Промоматериалы', selected: false},
      {icon: 'pie-chart.png', text: 'Статистика', selected: false},
      {icon: 'wallet.png', text: 'Баланс', selected: false},
      {icon: 'profile.png', text: 'Персональные данные', selected: false},
      {icon: 'information.png', text: 'FAQ', selected: false},
      {icon: 'music-and-multimedia.png', text: 'Техподдержка', selected: false},
      {icon: 'logout.png', text: 'Выход', selected: false}
    ];

    return (
      <div className={'menu'}>
        <div className={'logo'}></div>
        <div className={'menu-elements'}>
          <>
            {menuElements.map((element, index) => (
              <MenuElement icon={element.icon} text={element.text} selected={element.selected} key={index+element.icon}/>
            ))}
          </>
        </div>
      </div>
    );
  }
}

export default Menu;
