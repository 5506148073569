import React, {Component} from 'react';

class MenuElement extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {icon, text, selected} = this.props;

    return (
      <div className={'menu-element'}>
        <div className={`${selected ? 'menu-element-selected' : ''}`}></div>
        <div className={'icon'} style={{ background: `url('${icon}') center no-repeat`, }}>
        </div>
        <div className={`${!selected ? 'text-selected' : 'text'}`}>
          {text}
        </div>
      </div>
    );
  }
}

export default MenuElement;
