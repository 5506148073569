import React, {Component} from 'react';

class Envelope extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className={'envelope-holder'}>
        <div className={'envelope'}>
          <div className={'ellipse'}></div>
        </div>
      </div>
    );
  }
}

export default Envelope;
